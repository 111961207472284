// Profitability Report routes
export const profitabilityReportRoute = {
  path: '/dashboard/future-investor',
  name: 'hz-dashboard-future-investor',
}

// Real Estate Ads routes
export const realEstateAdsListRoute = {
  path: '/real-estate-ads',
  name: 'real-estate-ads',
}
export const addRealEstateAdRoute = {
  path: '/real-estate-ads/add',
  name: 'add-real-estate-ad',
}

export const updateRealEstateAdRoute = {
  path: '/real-estate-ads/edit/:id',
  name: 'edit-real-estate-ad',
}

// Tenants routes
export const tenantsRoute = {
  path: '/tenants',
  name: 'tenants',
}

// My assets routes
export const realEstatesRoute = {
  path: '/real-estates',
  name: 'real-estates',
}

export const myAssetsPerformanceRoute = {
  path: '/performance',
  name: 'performance',
}

// Documents routes
export const allDocumentRoute = {
  path: '/all-documents',
  name: 'iframe-user-documents',
}

export const documentTemplatesRoute = {
  path: '/document/templates',
  name: 'document-templates',
}

// Operations routes
export const operationsRoute = {
  path: '/operations',
  name: 'operations',
}

// Admin - Real Estate Ads
export const adminRealEstateAdsRoute = {
  name: 'hz-admin-real-estate-ads',
}
export const adminAgenciesRoute = {
  name: 'hz-admin-agencies',
}
export const adminCustomersRoute = {
  name: 'hz-admin-customers',
}

export const adminPaymentProductsRoute = {
  name: 'hz-admin-payment-products',
}

// Admin - Real Estate Ad Details
export const adminRealEstateAdDetailsRoute = {
  name: 'hz-admin-real-estate-ad-details',
}

// Admin - Notification templates
export const adminNotificationTemplatesRoute = {
  name: 'hz-admin-notification-templates',
}
