import { catch404ToNull, HOOKS } from '@/services/api'

const BASE_PATH_V1 = '/v1/users'

async function find(params) {
  return await HOOKS.get(BASE_PATH_V1, { params })
}

async function getUserInfos(userId) {
  return await HOOKS.get(`${BASE_PATH_V1}/${userId}`)
}

async function offerRealEstateTokens(payload) {
  return await HOOKS.post(`${BASE_PATH_V1}/offer-real-estate-tokens`, payload)
}

async function getConnectUserInfos() {
  return await HOOKS.get(`${BASE_PATH_V1}/me`)
}

async function updateConnectUser(connectedUserInfos) {
  return await HOOKS.patch(`${BASE_PATH_V1}/me`, connectedUserInfos)
}

async function getDeleteRequest() {
  return await HOOKS.get(`${BASE_PATH_V1}/me/delete-request`).catch(catch404ToNull)
}

async function createDeleteRequest() {
  return await HOOKS.post(`${BASE_PATH_V1}/me/delete-request`, {})
}

const PRO_ID_BASE_PATH_V1 = '/v1/professional-identities'

function getProfessionalIdentities() {
  return HOOKS.get(PRO_ID_BASE_PATH_V1)
}

function createProfessionalIdentity(params) {
  return HOOKS.post(PRO_ID_BASE_PATH_V1, params)
}

function updateProfessionalIdentity(id, params) {
  return HOOKS.put(`${PRO_ID_BASE_PATH_V1}/${id}`, params)
}

function deleteProfessionalIdentity(id) {
  return HOOKS.delete(`${PRO_ID_BASE_PATH_V1}/${id}`)
}

const API_KEYS_BASE_PATH_V1 = '/v1/api-keys'

function getApiKeys() {
  return HOOKS.get(API_KEYS_BASE_PATH_V1)
}

function createApiKey(params) {
  return HOOKS.post(API_KEYS_BASE_PATH_V1, params)
}

function deleteApiKey(id) {
  return HOOKS.delete(`${API_KEYS_BASE_PATH_V1}/${id}`)
}

function setUserProfile(params) {
  return HOOKS.post('/v1/users/me/profiles', params)
}

export const userService = {
  find,
  getUserInfos,
  offerRealEstateTokens,
  getConnectUserInfos,
  updateConnectUser,
  getDeleteRequest,
  createDeleteRequest,
  setUserProfile,
  getProfessionalIdentities,
  createProfessionalIdentity,
  updateProfessionalIdentity,
  deleteProfessionalIdentity,
  getApiKeys,
  createApiKey,
  deleteApiKey,
}

export const useUserService = () => userService
