// Do not remove LottiePlayer
// eslint-disable-next-line no-unused-vars
import * as LottiePlayer from '@dotlottie/player-component'

import { createApp, defineAsyncComponent } from 'vue'
import { createPinia } from 'pinia'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
import smoothscroll from 'smoothscroll-polyfill'

import { UikitPlugin, useDefaultValidators } from '@hz/ui-kit'

import { createKeycloak } from '@/keycloak'
import ConfigPLugin from '@/plugins/config'
import i18n from '@/i18n'
import store from '@/store'
import router from '@/router'
import { startMonitoring } from '@/core/logging/sentry'
import { config } from '@/core/config'
import { GtmPlugin } from '@/plugins'
import formatDateFromNow from '@/core/format-date-from-now'
import { extendDefaultValidators } from '@/helpers/validation'
import { initDirectives } from '@/directives'
import { usePermissionsStore } from '@/stores/permissions'

import HorizIoLoader from '@/components/HorizIoLoader.vue'

// Add kit styles
import '@hz/ui-kit/css'
import { useHzTolgee } from '@/tolgee'
import { startAppErrorsHandler } from '@/errors'
import { useLoaderStore } from '@/stores/loader'
import { useAuthStore } from '@/stores/auth'
import { useUserActionHistoryStore } from '@/stores/userActionHistory'
import { useUserProfileStore } from '@/stores/userProfile'
import { useAppNotificationsStore } from '@/stores/appNotifications'

// Add polyfills
smoothscroll.polyfill()

let loaderApp = null
let loaderMountedApp = null

export function displayAppLoading() {
  loaderApp = createApp(HorizIoLoader)
  loaderMountedApp = loaderApp.mount('#horiz-io-loader')
}

export function unmountAppLoading() {
  setTimeout(() => {
    loaderMountedApp.hide()
  }, 700)
  setTimeout(() => {
    loaderApp.unmount()
  }, 1400)
}

export function displayAppNotCreated() {
  const app = createApp(defineAsyncComponent(() => import('@/views/AppNotCreated.vue')))

  app.use(ConfigPLugin)

  app.use(i18n)
  app.use(store)

  app.mount('#app')
}

async function start() {
  try {
    const pinia = createPinia()

    // special App.vue for the PDF of the simulation report
    if (window.location.pathname.startsWith('/pdf-report')) {
      const app = createApp(defineAsyncComponent(() => import('@/AppPDF.vue')))
      app.use(i18n)
      await useHzTolgee(app)

      app.use(pinia)
      await createKeycloak()

      app.use(store)
      app.use(router)

      app.mount('#app')
    } else {
      displayAppLoading()
      const app = createApp(defineAsyncComponent(() => import('@/App.vue')))
      startAppErrorsHandler(app)
      startMonitoring(app, router)
      app.use(pinia)

      await createKeycloak()

      await usePermissionsStore().refreshQuotasAndPermissions()

      if (useAuthStore().isLoggedIn) {
        await Promise.all([
          useUserActionHistoryStore().fetch(),
          useUserProfileStore().fetchUserProfile(),
          useAppNotificationsStore().startFetchUnreadCount(),
        ])
      }

      app.use(ConfigPLugin)
      app.use(OneSignalVuePlugin, {
        appId: config.onesignal.apiId,
        safari_web_id: config.onesignal.safariWebId,
        welcomeNotification: {
          title: 'Notifications activées !',
          message: 'Vous serez informé des événements importants.',
        },
      })

      const trackingIsEnable = config.features.tracking

      app.use(GtmPlugin, {
        url: config.google.gtm.url,
        containerId: config.google.gtm.containerId,
        isEnabled: trackingIsEnable,
        router,
      })

      app.use(i18n)
      await useHzTolgee(app)
      app.use(store)
      app.use(router)

      app.use(UikitPlugin, {
        i18n,
        helpers: {
          formatDateFromNow,
        },
      })

      useDefaultValidators(i18n)
      extendDefaultValidators()

      initDirectives(app)

      app.mount('#app')
      app.config.globalProperties.$app = app
      useLoaderStore().start(loaderMountedApp)
    }
  } catch (e) {
    console.log(e)
    displayAppNotCreated()
    unmountAppLoading()
  }
}

start()
