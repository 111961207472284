import dateFnsFormat from 'date-fns/format'
import dateFnsFormatDistanceToNow from 'date-fns/formatDistanceToNow'
import dateFnsFormatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import dateFnsFormatDuration from 'date-fns/formatDuration'

import { fr } from 'date-fns/locale'

// also https://kazupon.github.io/vue-i18n/guide/datetime.html

const locales = { fr }
const locale = locales['fr']

const dateFormatWithHours = "dd/LL/yyyy à HH'h'mm" // ==> 17/08/2021 à 11h33
const dateFormatWithoutHours = 'dd/LL/yyyy' // ==> 17/08/2021
const dateFormatMonthLettersWithoutHours = 'dd LLLL yyyy' // ==> 17 avril 2021

const format = (date, formatStr, options) => dateFnsFormat(date, formatStr, { locale, ...options })
const formatDistanceToNow = (date, options) => dateFnsFormatDistanceToNow(date, { locale, ...options })
const formatDistanceToNowStrict = (date, options) =>
  dateFnsFormatDistanceToNowStrict(date, { locale, ...options })
const formatDuration = (duration, options) => dateFnsFormatDuration(duration, { locale, ...options })

export {
  dateFormatWithHours,
  dateFormatWithoutHours,
  dateFormatMonthLettersWithoutHours,
  format,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  formatDuration,
}
