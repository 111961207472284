import { shallowRef, defineAsyncComponent } from 'vue'
import { PromiseWithLoading, userLoading } from '@/helpers'
import { defineStore } from 'pinia'
import { userService } from '@/services/user.service'
import { useDrawerStore } from '@hz/ui-kit'
import { useUserStore } from '@/stores/user'
import { useUserProductStore } from '@/stores/userProduct'
import { AppException } from '@/core/exception/app-exception'

export const profileAttributes = {
  realEstateProfessional: 'real-estate-professional',
  lastName: 'last-name',
  firstName: 'first-name',
  phoneNumber: 'phone',
  address: 'address-1',
  postalCode: 'postal-code',
  city: 'city',
  country: 'country',
  company: 'company',
  rcs: 'rcs',
}

export const useUserProfileStore = defineStore('app-user-profile', {
  state: () => ({
    apiKeys: [],
    profile: null,
    deleteRequest: null,
  }),
  getters: {
    getAllApiKeys: (state) => {
      return state.apiKeys
    },
    areLastAndFirstNameDefined: (state) => {
      if (!state.profile) return false

      const user = state.profile.user

      return user.last_name !== '' && user.first_name !== ''
    },
    isAddressDefined: (state) => {
      if (!state.profile) return false

      const user = state.profile.user

      return user.address1 !== '' && user.codepostal !== '' && user.ville !== '' && user.country !== ''
    },
  },
  actions: {
    async fetchUserProfile() {
      const { start, stop } = userLoading('fetch-user-profile')

      try {
        start()
        this.profile = await userService.getConnectUserInfos()
      } catch (error) {
        throw new AppException(error)
      } finally {
        stop()
      }

      return this.profile
    },
    async updateUserProfile(profile) {
      const { start, stop } = userLoading('update-user-profile')

      try {
        start()
        this.profile = await userService.updateConnectUser(profile)
      } catch (error) {
        throw new AppException(error)
      } finally {
        stop()
      }

      return this.profile
    },

    async fetchDeleteRequest() {
      const { start, stop } = userLoading('fetch-user-delete-request')

      try {
        start()
        this.deleteRequest = await userService.getDeleteRequest()
      } catch (error) {
        throw new AppException(error)
      } finally {
        stop()
      }

      return this.profile
    },
    async createDeleteRequest() {
      const { start, stop } = userLoading('create-user-delete-request')

      try {
        start()
        this.deleteRequest = await userService.createDeleteRequest()
      } catch (error) {
        throw new AppException(error)
      } finally {
        stop()
      }
    },

    fetchApiKeys({ force } = { force: false }) {
      if (force || !this.apiKeys.length) {
        return PromiseWithLoading((resolve, reject) => {
          userService
            .getApiKeys()
            .then((res) => {
              this.apiKeys = res.apiKeys
              resolve(res.apiKeys)
            })
            .catch((error) => {
              reject(error)
            })
        })
      }
    },
    addApiKey(apiKey) {
      this.apiKeys = [...this.apiKeys, apiKey]
    },
    removeApiKey(id) {
      this.apiKeys = this.apiKeys.filter((key) => key._id !== id)
    },
    openApiKeyDrawer() {
      if (useUserStore().hasProOffer) {
        useDrawerStore().addDrawer({
          id: 'drawer-list-api-keys',
          component: shallowRef(
            defineAsyncComponent(() => import('@/components/Profile/DrawerListApiKeys.vue')),
          ),
        })
      } else {
        useUserProductStore().showUpgradeProDrawer('API keys')
      }
    },
    openPrivacyDrawer() {
      useDrawerStore().addDrawer({
        id: 'drawer-user-privacy',
        component: shallowRef(defineAsyncComponent(() => import('@/components/User/DrawerUserPrivacy.vue'))),
      })
    },
    openUserProfileDrawer(props = {}) {
      useDrawerStore().addDrawer({
        id: 'drawer-user-profile',
        component: shallowRef(defineAsyncComponent(() => import('@/components/User/DrawerUserProfile.vue'))),
        props,
      })
    },
  },
})
